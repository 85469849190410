module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5HXM16TWK9"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"453467843103587"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Osiedle Zielona Wzgórza","short_name":"Osiedlemlawa","start_url":"/","background_color":"#fff","theme_color":"#285A53","display":"minimal-ui","icon":"src/assets/favicon/favicon-32x32.png","icons":[{"src":"src/assets/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d8d46a3c4c3c9cd5f8fbb9991a34261"},
    }]
